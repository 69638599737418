<template>
    <div
        :class="{
            'checked-item': true,
            available: available && color === 'success',
            warning: dateColor('warning'),
            error: dateColor('error'),
            default: dateColor('default'),
        }"
        @mouseenter="mouseEnterInfo($event)"
        @mouseleave="mouseLeaveInfo"
    >
        <span
            class="checked-item__icon-container"
        >
            <AtomIcon
                v-if="icon"
                :name="available ? 'calendar' : 'lock'"
                width="12"
                bolding="2"
            />
        </span>
        <span class="text">{{ text }}</span>
    </div>
    <Teleport to="body">
        <AtomTooltip :element="tooltipElement" :offset-y="10" tooltip-type="default">
            <div class="tooltip-header">
                <span v-if="color === 'warning'">
                    {{ $t('Matrix.date_warning_tooltip_header', [text]) }}
                </span>
                <span v-if="color === 'error'">
                    {{ $t('Matrix.date_error_tooltip_header') }}
                </span>
            </div>
            <div>
                <div v-if="color === 'warning'" class="tooltip-text">
                    <span class="tooltip-text">
                        {{ $t('Matrix.date_warning_tooltip_text1') }}
                    </span>
                    <span class="tooltip-text tooltip-text-bold">
                        {{` ${text}`}}{{$t('Matrix.date_tooltip_time')}}
                    </span>
                    <span class="tooltip-text">
                        {{ $t('Matrix.date_warning_tooltip_text2') }}
                    </span>
                </div>
                <div v-if="color === 'error'">
                    <span class="tooltip-text">
                        {{ $t('Matrix.date_error_tooltip_text1') }}
                    </span>
                    <span class="tooltip-text tooltip-text-bold">
                        {{` ${text}`}}{{`${$t('Matrix.date_tooltip_time')} `}}
                    </span>
                    <span class="tooltip-text">
                        {{ $t('Matrix.date_error_tooltip_text2') }}
                    </span>
                </div>
            </div>
        </AtomTooltip>
    </Teleport>
</template>

<script lang="ts" setup>
const props = withDefaults(defineProps<{
    text: string,
    available?: boolean,
    icon?: boolean,
    color?: 'warning' | 'error' | 'default' | 'success',
}>(), {
    available: true,
    icon: true,
    color: 'success',
});

const dateColor = (styleColor: typeof props.color) => {
    return props.color === styleColor ? true : false;
};

const tooltipElement = ref<HTMLElement | null>(null);

const mouseEnterInfo = (event: MouseEvent) => {
    if (props.color === 'default' || props.color === 'success') {
        return;
    }

    tooltipElement.value = event.target as HTMLElement;
};

const mouseLeaveInfo = () => {
    tooltipElement.value = null;
};
</script>

<style lang="scss" scoped>
.checked-item {
    display: flex;

    @include helper-font-size(smaller);
    @include helper-font-weight(medium);
    @include helper-color-bg(alert-warning);

    align-items: center;
    white-space: nowrap;

    &__icon-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: rem(24);
        height: rem(24);
        border-radius: rem(10) 0 0 rem(10);
    }

    .text {
        display: flex;
        align-items: center;
        border-bottom-right-radius: rem(20);
        border-top-right-radius: rem(20);
        height: rem(24);
        padding: 0 rem(4);

        @include helper-border-t-b-r();
    }

    &.available {
        @include helper-color-bg(white);

        .checked-item__icon-container {
            @include helper-color-bg(alert-success);
        }

        .text {
            @include helper-border-color(alert-success);
            @include helper-color(alert-success);
        }

        .svg-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: rem(14);

            @include helper-color(white);
        }
    }

    &.default {
        background-color: inherit;
        margin-left: sp(xs);

        .checked-item__icon-container {
            @include helper-color-bg(gray-3);
        }

        .text {
            @include helper-border-color(gray-3);
            @include helper-color(gray-3);
            @include helper-color-bg(white);
        }

        .svg-icon {
            min-width: rem(16);

            @include helper-color(white);
        }
    }

    &.warning {
        background-color: inherit;
        margin-left: sp(xs);

        .checked-item__icon-container {
            @include helper-color-bg(alert-warning);
        }

        .text {
            @include helper-border-color(alert-warning);
            @include helper-color(alert-warning);
            @include helper-color-bg(white);
        }

        .svg-icon {
            min-width: rem(16);

            @include helper-color(white);
        }
    }

    &.error {
        background-color: inherit;
        margin-left: sp(xs);

        .checked-item__icon-container {
            @include helper-color-bg(alert-danger);
        }

        .text {
            @include helper-border-color(alert-danger);
            @include helper-color(alert-danger);
            @include helper-color-bg(white);
        }

        .svg-icon {
            min-width: rem(16);

            @include helper-color(white);
        }
    }
}

.tooltip-header {
    margin-bottom: sp(xs);

    @include helper-color-bg(white);
    @include helper-font-size(small);
    @include helper-font-weight(medium);
    @include helper-font-line-height(4);
    @include helper-color-bg(white);
}

.tooltip-text {
    @include helper-color-bg(white);
    @include helper-font-size(smaller);
    @include helper-font-weight(normal);
    @include helper-font-line-height(5);
    @include helper-color-bg(white);
}

.tooltip-text-bold {
    @include helper-font-weight(medium);
}
</style>
